import React from "react";

const Header = () => {
  return (
    <a href="#" className="text-secondary h4 d-block" style={{ marginTop: 50 }}>
      DJW.WORLD
    </a>
  );
};

export default Header;
