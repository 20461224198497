import React from "react";
import { createRoot } from "react-dom/client";
import App from "./app";

const rootElement = document.createElement("div");
rootElement.id = "home-app";
document.body.appendChild(rootElement);
const root = createRoot(rootElement!);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
