import React, { FC } from "react";

const StyledDivider: FC = () => (
  <hr
    className="border-white my-4"
    style={{ borderWidth: 4, opacity: 1, width: 32 }}
  />
);

const Intro = () => {
  return (
    <div>
      <h3 className="text-white fw-bold display-1">Say Hi.</h3>
      <h3 className="text-white fw-bold h6 pt-3 mb-0">to</h3>
      <h3 className="text-white fw-bold h6">Davin Jaya Wisastra</h3>
      <StyledDivider />
      <p className="text-white body-1">
        A software developer that believe in the importance of UI/UX and also in
        love with musics! Reach him out!
      </p>
      <StyledDivider />
      <span className="text-white body-2">v2.3.2</span>
    </div>
  );
};

export default Intro;
