import React, { FC } from "react";

const SafeArea: FC<{ children: JSX.Element }> = ({ children }) => (
  <div
    className="bg-primary d-flex flex-column"
    style={{ width: "100vw", minHeight: "100vh" }}
  >
    {children}
  </div>
);

export default SafeArea;
