import React, { FC } from "react";
import { Intro, SafeArea, ModelLoader, Footer, Header } from "./components";
import { Col, Row, Container, ContainerProps } from "react-bootstrap";
import classNames from "classnames";

import "./app.scss";

export default function App() {
  return (
    <SafeArea>
      <Container className="h-100 d-flex flex-column justify-content-between flex-grow-1 gap-5">
        <Row className="g-1">
          <Col xs={12} md={{ offset: 10, span: 2 }}>
            <Header />
          </Col>
        </Row>
        <Row className="g-1 flex-grow-1">
          <Col xs={12} md={{ offset: 1, span: 4 }} className="align-self-end">
            <Intro />
          </Col>
          <Col
            xs={12}
            md={{ offset: 1, span: 6 }}
            className="align-self-stretch"
          >
            <ModelLoader />
          </Col>
        </Row>
        <Row className="g-1">
          <Col xs={12} md={{ offset: 1, span: 11 }}>
            <Footer />
          </Col>
        </Row>
      </Container>
    </SafeArea>
  );
}
