import React, { FC } from "react";

const Footer = () => {
  return (
    <div
      className="d-flex align-items-center flex-wrap"
      style={{ height: 128 }}
    >
      <a
        className="text-secondary h4 me-4"
        href="https://www.instagram.com/davindjw"
        target="_blank"
      >
        Instagram
      </a>
      <a
        className="text-secondary h4 me-4"
        href="https://www.linkedin.com/in/davin-jaya-wisastra-38513811a"
        target="_blank"
      >
        Linkedin
      </a>
      <a
        className="text-secondary h4 me-4"
        href="mailto:davinjayawork@gmail.com"
      >
        Mail
      </a>
      <a
        className="text-secondary h4"
        href="https://github.com/davinjaya98"
        target="_blank"
      >
        Github
      </a>
    </div>
  );
};

export default Footer;
