import React, { Suspense, useRef, useState } from "react";
const Spline = React.lazy(() => import("@splinetool/react-spline"));
import { Application, SplineEvent } from "@splinetool/runtime";
import { Button, Fade, Spinner } from "react-bootstrap";
import classNames from "classnames";

const ModelLoader = () => {
  const spline = useRef<Application>();
  const [loading, isLoading] = useState<boolean>(true);

  const loadSpline = (splineApp: Application) => {
    if (!splineApp.disposed) {
      spline.current = splineApp;
      isLoading(false);
    }
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 position-relative">
      <div className={classNames("position-absolute top-50 start-50 translate-middle z-1", { 'opacity-0': !loading })}>
        <Spinner animation="border" variant="secondary" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
      <Suspense>
        <Fade in={!loading}>
          <div>
            <Spline
              scene="https://s3.ap-southeast-1.amazonaws.com/djw.world/assets/room_portfolio.splinecode"
              onLoad={loadSpline}
            />
            <span className="text-white mt-2 body-2">
              Try clicking anything! Some objects are clickable ;)
            </span>
          </div>
        </Fade>
      </Suspense>
    </div >
  );
};

export default ModelLoader;
